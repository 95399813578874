.aboutBlurb {
    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    /* font */
    color: #C9C9C9;
    font-family: 'HelveticaNeue';
}

.text {
    margin-top: 1em;
}

@media (min-width: 1400px) {
    .text {
        font-size: 1rem;
    }
}

@media (max-width: 1399px) {
    .text {
        font-size: 1rem;
    }
}

@media (max-width: 1199px) {
    .aboutBlurb {
        /* shape */
        width: 58%;
    }

    .text {
        font-size: 0.9rem;
    }
}

@media (max-width: 992px) {
    .aboutBlurb {
        /* shape */
        width: 58%;
    }

    .text {
        font-size: 0.8rem;
    }
}

@media (max-width: 767px) {
    .aboutBlurb {
        /* shape */
        width: 60%;
    }

    .text {
        font-size: 0.8rem;
    }
}

@media (max-width: 575px) {
    .aboutBlurb {
        /* shape */
        width: 100%;
    }

    .text {
        font-size: 0.8rem;
    }
}

@media (max-width: 460px) {
    .aboutBlurb {
        /* shape */
        width: 100%;
    }

    .text {
        font-size: 1rem;
    }
}

@media (max-width: 370px) {
    .text {
        font-size: 1rem;
    }
}
