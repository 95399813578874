body {
  background: #292929;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  cursor: url(assets/cursor.svg), auto;
}

a:hover {
  cursor: url(assets/pointer.svg) 9 0, auto;
}

p:hover {
  cursor: url(assets/cursorLight.svg), auto;
}

p {
  line-height: 1.35;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Roslindale";
  src: local("Roslindale"),
    url("./assets/fonts/Roslindale-DisplayRegular-Testing.otf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue";   /*Can be any text*/
  src: local("HelveticaNeue"),
    url("./assets/fonts/HelveticaNeue.ttc") format("truetype");
}
