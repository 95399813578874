.headers {
    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* font */
    color: #FBFBFB;
    font-family: 'Roslindale';
    font-size: 1.5rem;

    /* spacing */
    margin-top: 2vh
}

.text {
    /* spacing */
    margin-top: 0;
}

@media (min-width: 1200px) {
    .headers {
        font-size: 1.5rem;
    
        /* spacing */
        margin-bottom: -2vh;
    }
}

@media (max-width: 1199px) {
    .headers {
        font-size: 1.3rem;
    
        /* spacing */
        margin-bottom: -2vh;
    }
}

@media (max-width: 992px) {
    .headers {
        font-size: 1.2rem;
    
        /* spacing */
        margin-bottom: -2vh;
    }
}

@media (max-width: 767px) {
    .headers {
        font-size: 1.2rem;
    
        /* spacing */
        margin-bottom: -2vh;
    }
}

@media (max-width: 575px) {
    .headers {
        font-size: 1.5rem;
    
        /* spacing */
        margin-bottom: -3vh;
    }
}

@media (max-width: 460px) {
    .headers {
        font-size: 1.5rem;
    
        /* spacing */
        margin-bottom: -3vh;
    }
}
