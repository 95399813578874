.contact {
    /* spacing */
    margin-top: 1em;

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    /* font */
    color: #C9C9C9;
    font-family: 'HelveticaNeue';
}

a {
    line-height: 1.5;
}

a:link {
    /* font */
    color: #C9C9C9;
  }

a:visited {
    /* font */
    color: #C9C9C9;
}

a:hover {
    /* font */
    color: #FFFFFF;
    font-weight: 800;
}

@media (min-width: 1400px) {
    .contact {
        margin-left: 1rem;
        margin-top: 0.9rem;
        text-align: right;
    }

    a {
        font-size: 1rem;
    }
}

@media (max-width: 1399px) {
    .contact {
        margin-left: 1rem;
        margin-top: 0.9rem;
        text-align: right;
    }

    a {
        font-size: 1rem;
    }
}

@media (max-width: 1199px) {
    .contact {
        margin-left: 1rem;
        margin-top: 0.75rem;
        text-align: right;
    }

    a {
        font-size: 0.9rem;
    }
}

@media (max-width: 992px) {
    .contact {
        margin-left: 1rem;
        text-align: right;
    }

    a {
        font-size: 0.8rem;
    }
}

@media (max-width: 767px) {
    .contact {
        margin-left: 2rem;
        margin-top: 0.5rem;
        text-align: right;
    }

    a {
        font-size: 0.8rem;
    }
}

@media (max-width: 575px) {
    .contact {
        margin-left: 0;
        text-align: left;
    }

    a {
        font-size: 0.8rem;
    }
}

@media (max-width: 460px) {
    .contact {
        margin-left: 0;
        text-align: left;
    }

    a {
        font-size: 1rem;
    }
}

@media (max-width: 370px) {
    a {
        font-size: 1rem;
    }
}
