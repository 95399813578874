.App {
  /* colour */
  background: #292929;

  /* flex */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section {
  /* flex */
  align-items: center;
  display: flex;
  justify-content: center;

  /* scroll */
  overflow-y: scroll;

  /* sizing */
  min-height: 100vh;
}

@media (max-width: 430px) {
  section {
    align-items: flex-start;
    margin-top: -8vh;
  }
}
