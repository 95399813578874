.aboutSection {
    /* flex */
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.about {
    /* flex */
    flex-direction: column;
    display: flex;

    /* position */
    left: 0vw;
    position: relative;

    /* shape */
    width: 40vw;
}

.aboutContact {
    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headers {
    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* font */
    font-family: 'Roslindale';
}

.img {
    /* position */
    position: absolute;

    /* shape */
    height: auto;

    /* spacing */
    margin: 2vh 0 0 0;
    padding: 0;
}

@media (min-width: 1200px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 45vw;
    }

    .aboutContact {
        padding-bottom: 2vh;
    }

    .img {
        left: 19vw;
    
        width: 5vw;
    }
}

@media (max-width: 1199px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 45vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding-bottom: 1vh;
    }

    .img {
        left: 19vw;
    
        width: 5vw;
    }
}

@media (max-width: 992px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 45vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        padding-bottom: 1vh;
    }

    .img {
        left: 19vw;
    
        width: 5vw;
    }
}

@media (max-width: 767px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 45vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding-bottom: 1vh;
    }

    .img {
        left: 19vw;
    
        width: 5vw;
    }
}

@media (max-width: 767px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 45vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding-bottom: 1vh;
    }

    .img {
        left: 16vw;
    
        width: 6vw;
    }
}

@media (max-width: 575px) {
    .aboutSection {
        margin: 16vh 0;
    }

    .about {
        width: 50vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        padding-bottom: 0.25vh;
    }

    .img {
        display: none;
    }
}

@media (max-width: 460px) {
    .aboutSection {
        margin: 18vh 0;
    }

    .about {
        width: 72vw;
    }

    .aboutContact {
        /* flex */
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
    
        /* spacing */
        padding-bottom: 0.25vh;
    }

    .img {
        display: none;
    }
}
