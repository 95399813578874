.header {
    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    /* font */
    color: #FBFBFB;
    font-family: 'HelveticaNeue';
    font-weight: 600;
}

.description {
    /* font */
    color: #C9C9C9;
    font-family: 'HelveticaNeue';
}

.date {
    text-align: right;
}

@media (min-width: 1400px) {
    .header {
        /* font */
        font-size: 1.1rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 1rem;
    }

    .date {
        white-space: nowrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.5px solid #FBFBFB;
        display: block;
        height: 0.5px;
    
        /* spacing */
        margin-top: 2.5vh;
    }
}

@media (max-width: 1399px) {
    .header {
        /* font */
        font-size: 1rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 0.9rem;
    }

    .date {
        white-space: nowrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.5px solid #FBFBFB;
        display: block;
        height: 0.5px;
    
        /* spacing */
        margin-top: 2.5vh;
    }
}

@media (max-width: 1199px) {
    .header {
        /* font */
        font-size: 1rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 0.9rem;
    }

    .date {
        white-space: nowrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 2vh;
    }
}

@media (max-width: 992px) {
    .header {
        /* font */
        font-size: 0.9rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 0.8rem;
    }

    .date {
        white-space: nowrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 2vh;
    }
}

@media (max-width: 767px) {
    .header {
        /* font */
        font-size: 0.9rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 0.8rem;
    }

    .date {
        white-space: nowrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 2vh;
    }
}

@media (max-width: 575px) {
    .header {
        /* font */
        font-size: 0.9rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -1.5vh;
    }

    .description {
        font-size: 0.8rem;
    }

    .date {
        white-space: wrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 2vh;
    }
}

@media (max-width: 460px) {
    .header {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        /* font */
        font-size: 1.05rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -3vh;
    }

    .description {
        font-size: 1rem;
    }

    .date {
        white-space: wrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 1.5vh;
    }
}

@media (max-width: 370px) {
    .header {
        /* flex */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        /* font */
        font-size: 1.05rem;

        /* sizing */
        width: 100%;

        /* spacing */
        margin-top: -0.5vh;
        margin-bottom: -3vh;
    }

    .description {
        font-size: 1rem;
    }

    .date {
        white-space: wrap;
    }

    .line {
        /* shape */
        border: 0;
        border-top: 0.25px solid #FBFBFB;
        display: block;
        height: 0.25px;
    
        /* spacing */
        margin-top: 1vh;
    }
}
